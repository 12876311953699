import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Page404 from "./pages/errors/404";
import Home from "./pages/Home";
import Store from "./pages/Store";
import Cart from "./pages/Cart";
import Team from "./pages/Team";
import Rules from "./pages/Rules";
import Account from "./pages/Account";
import Checkout from "./pages/Checkout";


function Layout1({store}) {

    return (
                <Router>
                    <Routes>
                        <Route path="/" element={<Home store={store}/>}/>
                        <Route path="/store" element={<Store store={store}/>}/>
                        <Route path="/cart" element={<Cart store={store}/>}/>
                        <Route path="/team" element={<Team store={store}/>}/>
                        <Route path="/rules" element={<Rules store={store}/>}/>
                        <Route path="/account" element={<Account store={store}/>}/>
                        <Route path="/checkout" element={<Checkout store={store} />}/>


                        <Route path="/404" element={<Page404/>}/>
                        <Route path="*" element={<Page404/>}/>
                    </Routes>
                </Router>
    );
}

export default Layout1;