import {Link} from 'react-router-dom';
import React from 'react';
import Header from '../../../../components/Header';
import Footer from '../../../../components/Footer';
import Banner from '../../../../components/Banner';

function Rules({store}) {

    return (
        <>
            <Header store={store}/>

            <section className="bg-background text-white flex items-center justify-center">
                <div className="container px-5 mt-[1rem] pb-[3rem] relative">
                    <Banner store={store}/>

                    <div className="bg-[#1E1E1E] mt-8 rounded-lg shadow-lg p-8 w-full">
                        <h2 className="text-3xl font-semibold text-center mb-6">Regras do Servidor</h2>

                        <ul className="list-disc pl-6 space-y-4 text-lg">
                            <li>
                                <strong>Respeito</strong> - Respeite todos os jogadores, sem exceções. Qualquer tipo de
                                assédio será punido.
                            </li>
                            <li>
                                <strong>Sem Trapaças</strong> - O uso de cheats, bots ou qualquer outra forma de trapaça
                                resultará em banimento imediato.
                            </li>
                            <li>
                                <strong>Comportamento Adequado</strong> - Mantenha um comportamento educado e evite
                                discussões.
                            </li>
                            <li>
                                <strong>Propriedade de Outros Jogadores</strong> - Não invada ou destrua construções e
                                propriedades de outros jogadores sem permissão.
                            </li>
                            <li>
                                <strong>Mods Permitidos</strong> - Apenas mods aprovados pela administração do servidor
                                são permitidos.
                            </li>
                            <li>
                                <strong>Divulgação</strong> - Não faça spam de links ou informações não relacionadas ao
                                servidor.
                            </li>
                        </ul>

                        <div className="flex justify-center mt-8">
                            <Link
                                to="/"
                                className="bg-green-600 hover:bg-green-700 text-white px-6 py-3 rounded-md text-lg font-semibold transition duration-300"
                            >
                                Voltar para o Início
                            </Link>
                        </div>
                    </div>
                </div>
            </section>

            <Footer store={store}/>
        </>
    );
}

export default Rules;
