import {useLocation, useNavigate} from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Header from '../../../../components/Header';
import Footer from '../../../../components/Footer';
import Banner from '../../../../components/Banner';
import { findVitrine } from "../../../../service/TemplateService";
import { useCart } from "../../../../context/cart/Cart";
import {useAccount} from "../../../../context/account/Account";

function Store({ store }) {

    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const navigate = useNavigate();

    const [categorySelected, setCategorySelected] = useState(null);
    const [categories, setCategories] = useState(null);
    const [products, setProducts] = useState(null);
    const [nick, setNick] = useState('');

    const { cart, addItem } = useCart();

    const { account, login } = useAccount();

    useEffect(() => {
        findVitrine(store.id).then(res => {
            if (res.error) {
                return;
            }

            setCategories(res.response.categories);
            setProducts(res.response.products);

            if (params.has("slug")) {
                setCategorySelected((params.get("slug")));
            }
        })
    }, [store.id]);

    const handleNickSubmit = (e) => {
        e.preventDefault();

        login(nick);
    };

    if (!categories || !products) {
        return null;
    }

    return (
        <>
            <Header store={store} />

            <section className="bg-[#121212] text-white flex items-center justify-center">
                <div className="container px-5 mt-[1rem] pb-[3rem] relative">
                    <Banner store={store} />

                    {!account.name ? (
                        <div
                            className="mx-auto text-center max-w-[30rem] bg-zinc-900 rounded-[2rem] contain-paint border border-zinc-950 mt-10">
                            <form onSubmit={handleNickSubmit}>
                                <a className="block font-bold uppercase py-5 px-1 bg-head border-b border-zinc-900">Por
                                    favor insira o seu nick no servidor</a>

                                <div className="p-5">
                                    <div>
                                        <label htmlFor="username"></label>
                                        <input
                                            type="text"
                                            name="username"
                                            id="username"
                                            placeholder="Seu usuário..."
                                            className="p-2 rounded-[2rem] text-black font-medium text-center"
                                            value={nick}
                                            onChange={(e) => setNick(e.target.value)}
                                        />
                                    </div>
                                    <div className="mt-4">
                                        <button
                                            type="submit"
                                            name="send"
                                            className="uppercase font-bold bg-green-500 px-4 py-3 rounded-[2rem] transition-colors hover:bg-green-600"
                                        >
                                            Continuar
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    ) : (
                        <div className="flex gap-6 py-5 flex-col lg:flex-row">
                            <div
                                className="rounded-xl p-6 bg-zinc-900 text-white w-full lg:w-[350px] h-[250px] shadow-2xl transform transition-all hover:scale-105 hover:shadow-xl border-b-4 border-green-500">
                                <h3 className="text-xl font-semibold text-white mb-3 text-left">Selecione uma
                                    Categoria</h3>
                                <div className="space-y-2">
                                    {(categories.length !== 0 ? categories.map(category => (
                                        <button
                                            className={`w-full py-3 bg-${categorySelected !== category.slug ? "transparent" : "green-700/80"} hover:bg-green-700/80 text-white rounded-md transition duration-300`}
                                            onClick={() => window.location.href = "?slug=" + category.slug}
                                        >
                                            {category.name}
                                        </button>
                                    )) : (
                                        <button
                                            className="w-full py-3 bg-transparent text-white rounded-md transition duration-300"
                                            onClick={(e) => e.preventDefault()}
                                        >
                                            Nenhum categoria foi encontrada.
                                        </button>
                                    ))}
                                </div>
                            </div>

                            <div className="lg:w-2/3 grid grid-cols-1 sm:grid-cols-4 lg:grid-cols-4 gap-4">
                                {(products.length !== 0 ? products.filter(prod => (categorySelected ? prod.category === categorySelected : prod.category !== "")).map(product => (
                                    <div
                                        className="relative bg-muted w-full max-w-[280px] max-lg:max-w-full rounded-xl bg-zinc-900 bg-cover bg-no-repeat bg-center object-cover h-auto hover:ring-2 ring-green-700 transition">
                                        <div className="flex flex-col items-center p-6 h-full gap-2 rounded-xl">
                                            <div className="w-full flex justify-center items-center mb-auto">
                                                <div className="w-full flex justify-center items-start">
                                                    <img
                                                        className="max-h-full mx-auto rounded"
                                                        src={(product.image !== "" ? product.image : "https://cdn.lothus.shop/assets/product_not_found.webp")}
                                                        alt="Produto"
                                                    />
                                                </div>
                                            </div>

                                            <h2 className="font-semibold text-[23px] text-white">{product.name}</h2>
                                            <div className="flex flex-col items-center text-white leading-8">
                                                <span className="text-white text-opacity-50 text-base">por</span>
                                                <span
                                                    className="text-2xl font-semibold text-white">R$&nbsp;{product.price}</span>
                                            </div>

                                            <div
                                                className="mt-5 w-full transition rounded-b-xl flex items-center justify-center text-white">
                                                <button
                                                    className="h-14 w-[55px] rounded-l-lg flex items-center transition justify-center hover:shadow-center bg-green-600 brightness-110 hover:brightness-125"
                                                    onClick={() => alert('Ver detalhes do produto')}
                                                >
                                                    <i className="fa-solid fa-circle-question text-xl text-black/60"></i>
                                                </button>

                                                <button
                                                    onClick={(e) => {
                                                        addItem(product)
                                                    }}
                                                    className="h-14 flex items-center justify-center gap-2 font-medium text-lg w-[175px] whitespace-nowrap rounded-r-lg disabled:cursor-not-allowed transition hover:shadow-center bg-green-600 hover:brightness-110"
                                                >
                                                    <i className="fa-solid fa-cart-plus text-base text-white/60"></i>
                                                    Comprar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )) : (
                                    <div></div>
                                ))}
                            </div>
                        </div>
                    )}

                </div>
            </section>

            <Footer store={store}/>
        </>
    );
}

export default Store;
