import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {WebSocketProvider} from "./context/WebSocket";
import {CartProvider} from "./context/cart/Cart";
import {AccountProvider} from "./context/account/Account";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <WebSocketProvider>
            <AccountProvider>
                <CartProvider>
                    <App/>
                </CartProvider>
            </AccountProvider>
        </WebSocketProvider>
    </React.StrictMode>
);
