import React, { createContext, useContext, useEffect, useState, useRef } from "react";
import { useWebSocket } from "../WebSocket";

const CartContext = createContext(null);

export const CartProvider = ({ children }) => {
    const [cart, setCart] = useState([]);
    const { sendMessage, subscribe } = useWebSocket();

    const session = useRef(localStorage.getItem("session"));
    const store = useRef(localStorage.getItem("store"));

    useEffect(() => {
        if (!session.current || !store.current) return;

        subscribe(`/store/cart/${session.current}`, (callback) => {
            setCart(JSON.parse(callback.body));
            console.log(callback.body);
        });

        sendMessage("/user/cart/change", {
            action: "LOGIN",
            uniqueId: session.current,
            storeId: store.current,
        });

    }, [sendMessage, subscribe]);

    const addItem = (item) => {
        sendMessage(`/user/cart/change`, {
            action: "ADD_PRODUCT",
            uniqueId: session.current,
            productId: item.id,
            storeId: store.current,
            quantity: 1,
        });
    };

    const apply = (discount) => {
        sendMessage(`/user/cart/change`, {
            action: "ADD_COUPON",
            uniqueId: session.current,
            couponKey: discount,
            storeId: store.current,
        });
    };

    const remove = () => {
        sendMessage(`/user/cart/change`, {
            action: "REMOVE_COUPON",
            uniqueId: session.current,
            storeId: store.current
        })
    }

    const removeItem = (itemId) => {
        sendMessage(`/user/cart/change`, {
            action: "REMOVE_PRODUCT",
            uniqueId: session.current,
            productId: itemId,
            storeId: store.current,
            quantity: 1,
        });
    };

    const removeItems = (itemId, quantity) => {
        sendMessage(`/user/cart/change`, {
            action: "REMOVE_PRODUCT",
            uniqueId: session.current,
            productId: itemId,
            storeId: store.current,
            quantity: quantity,
        });
    };
    return (
        <CartContext.Provider value={{ cart, addItem, removeItem, apply, remove, removeItems }}>
            {children}
        </CartContext.Provider>
    );
};

export const useCart = () => {
    const context = useContext(CartContext);

    if (!context) {
        throw new Error(`useCart must be used within a CartProvider`);
    }

    return context;
};
