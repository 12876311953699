import React, { useState } from 'react';
import Header from '../../../../components/Header';
import Footer from '../../../../components/Footer';
import Banner from '../../../../components/Banner';
import { useCart } from '../../../../context/cart/Cart';
import { FaTrashAlt } from 'react-icons/fa';

function Checkout({ store }) {
    const { cart, removeItem } = useCart();
    const [selectedPayment, setSelectedPayment] = useState(null);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!selectedPayment) {
            alert("Por favor, selecione uma forma de pagamento.");
            return;
        }
        console.log("Forma de pagamento:", selectedPayment);
    };

    return (
        <>
            <Header store={store} />

            <section className="bg-[#121212] text-white flex items-center justify-center">
                <div className="container px-5 mt-0 pb-[3rem]">
                    <Banner store={store} />

                    <div className="py-5 flex flex-wrap lg:flex-nowrap gap-5 w-full h-fit">
                        <div className="bg-zinc-900 rounded-xl w-full lg:w-1/2">
                            <div className="flex justify-between items-center p-6 border-b border-zinc-700">
                                <h2 className="font-medium text-xl text-white">Resumo do Carrinho</h2>
                            </div>

                            <div className="p-6">
                                {cart.items.length ? (
                                    <>
                                        <table className="table-auto w-full text-sm text-left">
                                            <thead>
                                                <tr className="border-b border-zinc-700">
                                                    <th className="p-4 text-center">Produto</th>
                                                    <th className="p-4 text-center">Preço</th>
                                                    <th className="p-4 text-center">Quantidade</th>
                                                    <th className="p-4 text-center">Ações</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {cart.items.map((product) => (
                                                    <tr
                                                        key={product.id}
                                                        className="border-b border-zinc-700"
                                                    >
                                                        <td className="p-4 text-center">{product.name}</td>
                                                        <td className="p-4 text-center">R$ {product.price.toFixed(2)}</td>
                                                        <td className="p-4 text-center">{product.quantity}</td>
                                                        <td className="p-4 text-center">
                                                            <button
                                                                onClick={() => removeItem(product.id)}
                                                                className="text-red-500 hover:text-red-700"
                                                            >
                                                                <FaTrashAlt />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <div className="flex flex-col items-end mt-4">
                                            <p className="text-sl font-medium text-right">
                                            Sub-total: <span className="font-bold">R$ {cart.subTotal.toFixed(2)}</span>
                                            </p>
                                            <p className="text-sl font-medium text-right">
                                            Desconto aplicado: <span className="font-bold">R$ {cart.subTotalDiscount.toFixed(2)}</span>
                                            </p>
                                            <p className="text-lg font-medium text-right">
                                                Valor Total: <span className="font-bold">R$ {(cart.subTotal - cart.subTotalDiscount).toFixed(2)}</span>
                                            </p>
                                        </div>

                                    </>
                                ) : (
                                    <p className="text-center">Seu carrinho está vazio.</p>
                                )}
                            </div>
                        </div>

                        <div className="bg-zinc-900 rounded-xl w-full lg:w-1/2">
                            <div className="flex justify-between items-center p-6 border-b border-zinc-700">
                                <h2 className="font-medium text-xl text-white">Informações do Pedido</h2>
                            </div>

                            <form
                                onSubmit={handleSubmit}
                                className="p-6 flex flex-col gap-6 text-white"
                            >
                                <div className="grid grid-cols-2 gap-4">
                                    <div>
                                        <label
                                            htmlFor="firstName"
                                            className=" block text-sm font-medium mb-2"
                                        >
                                            Nome
                                        </label>
                                        <input
                                            type="text"
                                            id="firstName"
                                            name="firstName"
                                            className="bg-zinc-800 w-full p-3 rounded-lg text-black"
                                            placeholder="Seu nome"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label
                                            htmlFor="lastName"
                                            className="block text-sm font-medium mb-2"
                                        >
                                            Sobrenome
                                        </label>
                                        <input
                                            type="text"
                                            id="lastName"
                                            name="lastName"
                                            className="bg-zinc-800 w-full p-3 rounded-lg text-black"
                                            placeholder="Seu sobrenome"
                                            required
                                        />
                                    </div>
                                </div>

                                <div>
                                    <label
                                        htmlFor="email"
                                        className="block text-sm font-medium mb-2"
                                    >
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        className="bg-zinc-800 w-full p-3 rounded-lg text-black"
                                        placeholder="Seu email"
                                        required
                                    />
                                </div>

                                <div>
                                    <label className="block text-sm font-medium mb-2">
                                        Forma de Pagamento
                                    </label>
                                    <div className="grid grid-cols-1 sm:grid-cols-4 gap-4">
                                        <div
                                            onClick={() => setSelectedPayment("Mercado Pago")}
                                            className={`p-4 rounded-lg cursor-pointer border-2 transition ${selectedPayment === "Mercado Pago"
                                                    ? "border-green-500 bg-green-500/20"
                                                    : "border-zinc-700"
                                                }`}
                                        >
                                            <p className="text-lg font-medium text-center">
                                                Mercado Pago
                                            </p>
                                        </div>
                                        <div
                                            onClick={() => setSelectedPayment("PayPal")}
                                            className={`p-4 rounded-lg cursor-pointer border-2 transition ${selectedPayment === "PayPal"
                                                    ? "border-green-500 bg-green-500/20"
                                                    : "border-zinc-700"
                                                }`}
                                        >
                                            <p className="text-lg font-medium text-center">
                                                PayPal
                                            </p>
                                        </div>
                                        <div
                                            onClick={() => setSelectedPayment("Stripe")}
                                            className={`p-4 rounded-lg cursor-pointer border-2 transition ${selectedPayment === "Stripe"
                                                    ? "border-green-500 bg-green-500/20"
                                                    : "border-zinc-700"
                                                }`}
                                        >
                                            <p className="text-lg font-medium text-center">Stripe</p>
                                        </div>
                                        <div
                                            onClick={() => setSelectedPayment("Pix")}
                                            className={`p-4 rounded-lg cursor-pointer border-2 transition ${selectedPayment === "Pix"
                                                    ? "border-green-500 bg-green-500/20"
                                                    : "border-zinc-700"
                                                }`}
                                        >
                                            <p className="text-lg font-medium text-center">Pix</p>
                                        </div>
                                    </div>
                                </div>

                                <button
                                    type="submit"
                                    className="px-5 py-3 rounded-full bg-green-500 hover:bg-green-500/80 transition text-white font-medium"
                                >
                                    Confirmar Pedido
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            <Footer store={store} />
        </>
    );
}

export default Checkout;
