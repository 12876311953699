import React, {useState} from 'react';

function Banner({store}) {

    const [copied, setCopied] = useState(false);

    return (
        <div
            className="flex items-center lg:min-h-[250px] px-20 overflow-hidden lg:rounded-md absolute top-0 left-0 -z-[1] lg:z-0 lg:relative bg-cover bg-[50%] bg-no-repeat w-full min-h-[350px] before:content-[''] before:absolute before:blur-xl before:w-40 before:h-[160%] before:-left-[20%] before:bg-c-primary/[0.02] before:-z-[1] before:-top-[21%] before:rotate-[20deg] before:animate-banner bg-secondary justify-between"
            style={{backgroundImage: `url(${sessionStorage.getItem("banner")})`}}
        >
            <div
                className="absolute -bottom-20 left-[70%] -translate-x-[50%] w-[150px] h-[150px] bg-c-primary/30 rounded-full blur-3xl"></div>
            <div
                className="absolute -top-20 left-[30%] -translate-x-[50%] w-[150px] h-[150px] bg-c-primary/30 rounded-full blur-3xl"></div>

            <a className="hover:scale-110 transition-transform duration-500 hidden items-center justify-start gap-5 lg:flex"
               onClick={() => navigator.clipboard.writeText(store.serverIp).then(() => {
                   setCopied(true);

                   setInterval(() => {
                       setCopied(false);
                   }, 2000);
               })}>
        <span className="min-w-fit text-5xl text-textPrimary">
          <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 16 16"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                d="M11.5 6.027a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm-1.5 1.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm2.5-.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm-1.5 1.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm-6.5-3h1v1h1v1h-1v1h-1v-1h-1v-1h1v-1z"></path>
          </svg>
        </span>
                <div>
                    <h5 className="font-medium text-lg font-mono text-textPrimary font-semibold">CONECTE-SE JÁ</h5>
                    <p id="serverIp"
                       className="font-medium text-sm font-mono text-textPrimary">{(copied ? "Copiado!" : "Clique aqui para copiar.")}</p>
                </div>
            </a>

            <a
                className="hover:scale-110 transition-transform duration-500 hidden lg:block"
                href="/"
            >
                <img
                    alt="Lothus"
                    loading="lazy"
                    width="230"
                    height="230"
                    decoding="async"
                    data-nimg="1"
                    style={{color: 'transparent'}}
                    src={sessionStorage.getItem("logo")}
                />
            </a>

            <a className="hover:scale-110 transition-transform duration-500 hidden items-center justify-start gap-5 lg:flex"
               href={store.widgets.discordUrl}>
        <span className="min-w-fit text-5xl text-textPrimary">
          <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 16 16"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z"></path>
          </svg>
        </span>
                <div>
                    <h5 className=" font-medium text-lg font-mono text-textPrimary font-semibold">Entrar no Discord</h5>
                    <p className=" font-medium text-sm font-mono text-textPrimary">Participe da nossa comunidade do
                        discord</p>
                </div>
            </a>
        </div>
    );
};

export default Banner;
