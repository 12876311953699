import axios from 'axios';

const API_URL = 'https://api.lothus.shop';

export const findStore = async (hostname) => {
    try {
        const res = await axios.get(API_URL + `/web/${hostname}`, {
            "Content-Type": "application/json"
        });

        return res.data;
    } catch (error) {
        return {
            error: true,
            exception: error
        }
    }
}

export const findPosts = async (id) => {
    try {
        const res = await axios.get(API_URL + `/web/${id}/posts`, {});

        return res.data;
    } catch (error) {
        return {
            error: true,
            exception: error
        }
    }
}
export const findVitrine = async (id) => {
    try {
        const res = await axios.get(API_URL + `/web/${id}/vitrine`, {});
        return res.data;
    } catch (error) {
        return {
            error: true,
            exception: error
        }
    }
}