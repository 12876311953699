import React, {createContext, useContext, useEffect, useRef, useState} from 'react';
import {useWebSocket} from "../WebSocket";

const AccountContext = createContext(null);

export const AccountProvider = ({children}) => {

    const [account, setAccount] = useState({});

    const { sendMessage, subscribe } = useWebSocket();

    const store = useRef(localStorage.getItem("store"));
    const session = useRef(localStorage.getItem("session"));

    useEffect(() => {
        if (session.current && store.current) {
            subscribe("/store/account/" + session.current, (callback) => {
                const parse = JSON.parse(callback.body);
                setAccount(parse);
                console.log(parse);
            })

            sendMessage("/user/account/change", {
                action: "LOGIN",
                uniqueId: session.current,
                storeId: store.current
            });
        }
    }, [session.current, store.current]);

    const login = (name) => {
        sendMessage("/user/account/change", {
            action: "LOGIN",
            uniqueId: session.current,
            storeId: store.current,
            name: name
        });

        localStorage.setItem("username", name);
    }

    return (
        <AccountContext.Provider value={{ account, login }}>
            {children}
        </AccountContext.Provider>
    )
}

export const useAccount = () => {
    const context = useContext(AccountContext);

    if (!context) {
        throw new Error("useAccount must be used within a AccountProvider");
    }

    return context;
}