import React, {useEffect} from 'react';

const Page404 = () => {
    useEffect(() => {
        document.title = "Oops!";
    }, []);
    return (
        <div className="flex items-center justify-center min-h-screen bg-zinc-900">
            <div className="text-center">
                <h1 className="text-6xl font-bold text-green-500">404</h1>
                <p className="mt-4 text-2xl text-white">Oops! Loja não encontrada!</p>
                <p className="mt-2 text-slate-300">A loja que você está procurando não existe,</p>
                <p className="mt-2 text-slate-300">aproveite e transforme em sua!</p>
                <a
                    href="https://lothus.shop"
                    className="mt-6 inline-block px-4 py-2 text-white bg-green-500 rounded hover:bg-green-600 transition"
                >
                    Voltar para o site
                </a>
            </div>
        </div>
    );
};

export default Page404;
