import React from 'react';
import Header from '../../../../components/Header';
import Footer from '../../../../components/Footer';

function Account({store}) {
    return (
        <>
            <Header store={store}/>
            <section className="bg-background text-primary flex items-center justify-center min-h-screen">
                <div className="container px-5 mt-[1rem] pb-[3rem]">

                    {/* Seção de Informações da Conta */}
                    <div className="bg-zinc-900 text-white rounded-xl p-6 shadow-lg mb-8">
                        <h2 className="text-2xl font-semibold mb-4">Informações da Conta</h2>
                        <div className="flex flex-col gap-4">
                            <div className="flex justify-between items-center">
                                <span className="text-sm font-medium text-gray-300">Nome:</span>
                                <span className="text-sm font-medium">João da Silva</span>
                            </div>
                            <div className="flex justify-between items-center">
                                <span className="text-sm font-medium text-gray-300">Email:</span>
                                <span className="text-sm font-medium">joao.silva@email.com</span>
                            </div>
                            <div className="flex justify-between items-center">
                                <span className="text-sm font-medium text-gray-300">Nick In-Game:</span>
                                <span className="text-sm font-medium">JoaoGamer123</span>
                            </div>

                            {/* Botão para alterar senha */}
                            <div className="flex justify-end mt-4">
                                <button
                                    className="bg-green-600 w-full hover:bg-green-800 text-white font-medium py-2 px-4 rounded transition border border-green-900"
                                    onClick={() => alert("Função de alteração de senha em desenvolvimento")}
                                >
                                    Alterar informações
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* Seção de Últimos Pagamentos */}
                    <div className="bg-zinc-900 text-white rounded-xl p-6 shadow-lg">
                        <h2 className="text-2xl font-semibold mb-4">Últimos Pagamentos</h2>
                        <div className="flex flex-col gap-4">
                            {/* Exemplo de pagamento */}
                            {['Assinatura Premium', 'Produto XYZ'].map((item, index) => (
                                <div
                                    key={index}
                                    className="flex justify-between items-center bg-zinc-800 rounded-lg p-4"
                                >
                                    <div>
                                        <h3 className="text-lg font-semibold">{item}</h3>
                                        <p className="text-sm text-gray-400">Data: 01/11/2024</p>
                                    </div>
                                    <span className="text-green-400 font-medium">R$ 29,99</span>
                                </div>
                            ))}
                        </div>

                        {/* Nenhum pagamento encontrado */}
                        <div className="border text-muted-foreground p-6 rounded-xl mt-6 flex items-center gap-2">
                            <i className="fa-regular fa-credit-card text-3xl"></i>
                            <p>
                                Nenhum pagamento encontrado.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer store={store}/>
        </>
    );
}

export default Account;
