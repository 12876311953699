import React, { createContext, useContext, useEffect, useState } from 'react';
import SockJS from 'sockjs-client';
import { Client } from '@stomp/stompjs';

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
    const [messages, setMessages] = useState([]);
    const [stompClient, setStompClient] = useState(null);

    const [connected, setConnected] = useState(false);

    useEffect(() => {
        const socket = new SockJS('https://api.lothus.shop/ws');
        const client = new Client({
            webSocketFactory: () => socket,
            debug: (str) => {
                console.log(str);
            },
        });

        client.onConnect = (frame) => {
            setConnected(true);
            console.log('Connected: ' + frame);
        };

        client.onWebSocketError = (error) => {
            console.error('WebSocket error: ', error);
        };

        client.onStompError = (frame) => {
            console.error('Broker reported error: ' + frame.headers['message']);
            console.error('Additional details: ' + frame.body);
        };

        client.activate();
        setStompClient(client);

        return () => {
            client.deactivate();
        };
    }, []);

    if (!stompClient) return;

    const sendMessage = (destination, message) => {
        if (stompClient && stompClient.connected) {
            stompClient.publish({
                destination: destination,
                body: JSON.stringify(message)
            })
        }
    };
    
    const subscribe = (destination, callback) => {
        if (stompClient && stompClient.connected) {
            stompClient.subscribe(destination, callback);
        }
    }

    if (!connected) return;

    return (
        <WebSocketContext.Provider value={{ messages, sendMessage, subscribe }}>
            {children}
        </WebSocketContext.Provider>
    );
};

export const useWebSocket = () => {
    const context = useContext(WebSocketContext);
    if (!context) {
        throw new Error('useWebSocket must be used within a WebSocketProvider');
    }
    return context;
};