import {useEffect, useState} from "react";
import {findStore} from "./service/TemplateService";
import Layout1 from "./pages/templates/layout-1/Layout1";
import Page404 from "./pages/errors/404";
import Page402 from "./pages/errors/402";

function App() {

    const [error, setError] = useState(false);
    const [store, setStore] = useState(null);

    useEffect(() => {
        const fetchStore = async () => {
            const res = await findStore(window.location.hostname);

            if (res.error) {
                setError(true);
                return;
            }

            setStore(res.response);

            localStorage.setItem("store", res.response.id);
            sessionStorage.setItem("logo", res.response.style.logo);
            sessionStorage.setItem("favicon", res.response.style.favicon);

            document.documentElement.style.setProperty('--color-primary', res.response.style.primaryColor);
            document.documentElement.style.setProperty('--color-secondary', res.response.style.secondaryColor);
            document.documentElement.style.setProperty('--color-text-primary', res.response.style.textPrimaryColor);
            document.documentElement.style.setProperty('--color-text-secondary', res.response.style.textSecondaryColor);
            document.documentElement.style.setProperty('--color-background', res.response.style.backgroundColor);

            document.title = res.response.name + " - " + res.response.slogan;
        }

        fetchStore();
    }, []);

    if (error) return <Page404/>;

    if (store == null) return <div></div>;

    if (store.status !== 'ACTIVE') {
        return <Page402/>;
    }

    if (!localStorage.getItem("session")) {
        localStorage.setItem("session", crypto.randomUUID())
    }

    const TemplateComponent = getTemplateComponent(store.template);

    return (
        <TemplateComponent store={store}/>
    );
}


function getTemplateComponent(templateId) {
    switch (templateId) {
        case 'layout-2':
            return Layout1;
        default:
            return Layout1;
    }
}

export default App;
