import React, {useEffect, useState} from 'react';
import {useCart} from "../context/cart/Cart";
import {useAccount} from "../context/account/Account";
import {useNavigate} from "react-router-dom";

const Header = () => {

    const { account } = useAccount();
    const navigate = useNavigate();

    useEffect(() => {
        console.log(account);
    }, []);

    const {cart, addItem} = useCart();

    const [menuOpen, setMenuOpen] = useState(false);

    const handleMenuToggle = () => {
        setMenuOpen(prev => !prev);

    };

    const totalItems = () => {
        let i = 0;
        cart.items.map((prod) => {
            i += prod.quantity;
        })
        return i;
    }

    if (!account) return;

    return (
        <header className="flex items-center justify-center py-3 bg-background shadow-md">
            <div className="container px-8 flex items-center justify-between gap-2 w-full max-w-[1600px] mx-auto">

                <div className="flex items-center justify-start w-full">

                    <a href="/" className="flex items-center justify-center">
                        <img
                            src={sessionStorage.getItem("logo")}
                            alt="Logo"
                            className="h-24 w-auto"
                        />
                    </a>

                    <div className="hidden md:flex items-center gap-12 ml-6">
                        <nav>
                            <ul className="flex gap-12">
                                <li>
                                    <a
                                        href="/"
                                        className="flex gap-2 items-baseline tracking-[0.05em] text-lg text-white font-semibold"
                                    >
                                        <i className="fa-solid fa-house text-xl text-brand"></i>
                                        Ínício
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="/rules"
                                        className="flex gap-2 items-baseline tracking-[0.05em] text-lg text-white font-semibold"
                                    >
                                        <i className="fa-solid fa-check-to-slot text-xl text-brand"></i>
                                        Regras
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="/team"
                                        className="flex gap-2 items-baseline tracking-[0.05em] text-lg text-white font-semibold"
                                    >
                                        <i className="fa-solid fa-users text-xl text-brand"></i>
                                        Equipe
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>

                <div className="flex items-center gap-4">
                    <a
                        href="/cart"
                        className="hidden md:flex items-center gap-2 transition tracking-[0.05em] px-[15px] rounded-md py-2.5 text-lg text-white font-semibold w-auto cursor-pointer select-none"
                    >
                        <i className="fa-solid fa-cart-shopping text-xl text-white"></i>
                        <span className="bg-green-500 px-4 rounded-full">{totalItems()}</span>
                    </a>

                    <a
                        href="/store"
                        className="hidden md:flex items-center gap-2 bg-green-500 hover:bg-green-600 transition tracking-[0.05em] px-[40px] rounded-md py-2.5 text-lg text-white font-semibold w-auto cursor-pointer select-none"
                    >
                        <i className="fa-solid fa-gift text-base"></i>
                        Loja
                    </a>
                </div>

                <button
                    type="button"
                    id="menu-button"
                    className="mr-5 opacity-50 md:hidden"
                    onClick={handleMenuToggle}
                >
                    <i className="fa-solid fa-bars text-2xl text-white"></i>
                </button>

            </div>

            <div
                className={`w-full bg-background md:hidden ${menuOpen ? 'block' : 'hidden'} absolute top-0 left-0 right-0 bottom-0 z-50`}
                onClick={() => setMenuOpen(false)}
            >
                <nav className="h-full flex flex-col items-start justify-center pl-5">
                    <ul className="flex flex-col items-start gap-8 text-white text-lg font-semibold">
                        <li>
                            <a href="/" className="text-white">Ínício</a>
                        </li>
                        <li>
                            <a href="/rules" className="text-white">Regras</a>
                        </li>
                        <li>
                            <a href="/team" className="text-white">Equipe</a>
                        </li>
                        <li>
                            <a href="/store" className="text-white">Loja</a>
                        </li>
                        <li>
                            <a href="/cart" className="text-white">Carrinho</a>
                        </li>

                        {account.name && (
                            <li className="flex items-center gap-2">
                                <img
                                    src={account.avatar}
                                    alt={`${account.name}'s avatar`}
                                    className="w-8 h-8 rounded-full"
                                />
                                <span className='text-white '>Olá, {account.name}!</span>
                            </li>
                        )}
                    </ul>
                </nav>
            </div>
        </header>
    );
};

export default Header;
