import React from 'react';

function Footer({store}) {

    return (
        <footer className="bg-background text-textPrimary py-8">
            <div className="container mx-auto px-6">
                <div className="flex flex-col md:flex-row items-center justify-between">

                    <div className="mb-6 md:mb-0">
                        <img
                            src={sessionStorage.getItem("logo")}
                            alt="Logo do Servidor"
                            className="h-12"
                        />
                    </div>


                    <div
                        className="flex flex-col items-center md:items-start space-y-4 md:space-y-0 md:flex-row md:space-x-10">
                        <div className="text-center md:text-left">
                            <p className="text-sm text-gray-400">© 2024 LothusShop. Todos os direitos reservados.</p>
                        </div>
                    </div>


                    <div className="mt-6 md:mt-0 flex justify-center gap-6 text-gray-400">

                        <a
                            href="/termos"
                            className="flex items-center font-bold uppercase gap-2 text-gray-400 hover:text-white transition"
                        >
                            Termos
                        </a>


                        <a
                            href={store.widgets.discordUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="hover:text-white transition"
                        >
                            <i className="fab fa-discord text-2xl"></i>
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
