import React from 'react';
import Header from '../../../../components/Header';
import Footer from '../../../../components/Footer';
import Banner from '../../../../components/Banner';

function Team() {
    // Exemplo de dados para os jogadores
    const teamMembers = [
        {name: 'leozadaxz', role: 'CEO', imageUrl: 'https://mc-heads.net/avatar/leozadaxz/100.png'},
        {name: 'Bremado', role: 'CEO', imageUrl: 'https://minotar.net/avatar/Bremado/100.png'},
        {name: 'LM', role: 'CEO', imageUrl: 'https://minotar.net/avatar/SerasaSPC/100.png'},
    ];

    return (
        <>
            <Header/>

            <section className="bg-[#121212] text-white flex items-center justify-center">
                <div className="container px-5 mt-[1rem] pb-[3rem] relative">
                    <Banner/>

                    {/* Cards de membros da equipe */}
                    <div className="py-10">
                        <h2 className="text-center text-3xl mb-8">Nossa Equipe</h2>
                        <div className="flex flex-wrap justify-center gap-8">
                            {teamMembers.map((member, index) => (
                                <div key={index}
                                     className="bg-[#1a1a1a] p-6 rounded-lg shadow-lg max-w-[200px] text-center">
                                    <img
                                        src={member.imageUrl}
                                        alt={member.name}
                                        className="rounded-full w-24 h-24 mx-auto mb-4"
                                    />
                                    <h3 className="text-xl font-semibold">{member.name}</h3>
                                    <p className="text-sm text-gray-400">{member.role}</p>
                                </div>
                            ))}
                        </div>
                    </div>

                </div>
            </section>

            <Footer/>
        </>
    );
}

export default Team;
