import React, { useEffect, useState } from 'react';
import Header from '../../../../components/Header';
import Footer from '../../../../components/Footer';
import Banner from '../../../../components/Banner';
import { useCart } from "../../../../context/cart/Cart";
import { FaTrashAlt } from 'react-icons/fa';
import { useNavigate } from "react-router-dom"; // Importando o ícone da lixeira

function Cart({ store }) {

    const navigate = useNavigate();
    const { cart, addItem, removeItem, removeItems, apply, remove } = useCart();

    const [coupon, setCoupon] = useState('');

    if (!cart) return;

    const handleQuantityChange = (productId, action) => {
        if (action === 'increase') {
            addItem({id: productId})
        } else if (action === 'decrease') {
            removeItem(productId)
        }
    }

    return (
        <>
            <Header store={store} />

            <section className="bg-[#121212] text-white flex items-center justify-center">
                <div className="container px-5 mt-0 pb-[3rem]">
                    <Banner store={store} />
                    <div className="py-5 flex flex-wrap gap-5 w-full h-fit">
                        <div className="bg-zinc-900 rounded-xl w-full">
                            <div className="flex justify-between items-center p-6 border-b border-zinc-700">
                                <h2 className="font-medium text-xl text-white">
                                    Carrinho
                                </h2>
                            </div>

                            <div className="relative w-full">
                                <div className="overflow-auto">
                                    <table className="table-auto w-full text-sm text-left overflow-auto">
                                        <thead>
                                            <tr className="border-b border-zinc-700">
                                                <th className="p-6 font-medium uppercase text-muted-foreground text-center">Produto</th>
                                                <th className="p-6 font-medium uppercase text-muted-foreground text-center">Preço por Unidade</th>
                                                <th className="p-6 font-medium uppercase text-muted-foreground text-center">Preço Total</th>
                                                <th className="p-6 font-medium uppercase text-muted-foreground text-center">Quantidade</th>
                                                <th className="p-6 font-medium uppercase text-muted-foreground text-center">Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody className="text-white">
                                            {cart.items.length !== 0 ? cart.items.map(product => (
                                                <tr key={product.id} className="border-b border-zinc-700">
                                                    <td className="p-8 font-bold text-m text-center">{product.name}</td>
                                                    <td className="p-8 font-semibold text-m text-center">R$&nbsp;{(product.price).toFixed(2)}</td>
                                                    <td className="p-8 font-semibold text-m text-center">R$&nbsp;{(product.price * product.quantity).toFixed(2)}</td>
                                                    <td className="p-8 font-semibold text-m text-center">
                                                        <button onClick={() => handleQuantityChange(product.id, 'decrease')} className="px-3 py-1 bg-red-600 text-white rounded-full">-</button>
                                                        <span className="mx-2">{product.quantity}</span>
                                                        <button onClick={() => handleQuantityChange(product.id, 'increase')} className="px-3 py-1 bg-green-600 text-white rounded-full">+</button>
                                                    </td>
                                                    <td className="p-8 font-semibold text-m text-center">
                                                        <div className="flex justify-center mt-2">
                                                            <a onClick={() => removeItems(product.id, product.quantity)}>
                                                                <FaTrashAlt className="text-red-500 text-sm" />
                                                            </a>
                                                        </div>
                                                    </td>

                                                </tr>
                                            )) : (
                                                <tr className="table-row">
                                                    <td colSpan="5" className="text-center p-8 text-sm">
                                                        <div className="block">
                                                            <p className="mb-4">Nenhum produto encontrado no seu carrinho.</p>
                                                            <a href="/store"
                                                                className="py-2 px-3 rounded bg-green-400/10 hover:bg-green-500/20 transition text-green-400 font-semibold tracking-wider">
                                                                <i className="fa-solid fa-basket-shopping"></i> Explorar loja
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>

                                <form
                                    className="border-y border-zinc-700 text-white p-6 py-3 font-normal flex flex-wrap items-center justify-between gap-5 w-full whitespace-nowrap"
                                    onSubmit={(event) => event.preventDefault()}>
                                    <label htmlFor="coupon" className="max-sm:hidden text-sm">
                                        Tem um cupom de desconto?
                                    </label>

                                    <div className="flex items-center justify-between max-sm:w-full gap-2 h-full relative">
                                        {cart.coupon && (
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    setCoupon('');
                                                    remove();
                                                }}
                                                className="absolute left-2 text-red-500 hover:text-red-600 text-lg flex items-center justify-center">
                                                &times;
                                            </button>
                                        )}

                                        <input
                                            id="coupon"
                                            onChange={(e) => setCoupon(e.target.value)}
                                            value={coupon}
                                            className="h-8 text-slate-200 bg-zinc-800 rounded-full text-sm px-8"
                                            placeholder="Digite seu cupom"
                                            autoComplete="off"
                                        />

                                        <button
                                            onClick={() => apply(coupon)}
                                            className="rounded-full flex items-center gap-1 max-w-28 h-[30px] px-4 text-sm font-medium bg-green-500"
                                            data-coupon-action="apply">
                                            <i className="fa-solid fa-ticket text-white/70"></i>
                                            <span className="discount-label">Aplicar</span>
                                        </button>
                                    </div>
                                </form>


                                <div
                                    className="p-6 flex flex-col md:flex-row items-start md:items-center gap-4 whitespace-nowrap w-full">

                                    <div className="flex flex-col gap-2">
                                        <h2 className="text-sm font-medium text-white font-montserrat">
                                            Sub-total: <span className="text-brand font-bold cart-price text-base">
                                                R$&nbsp;{cart.subTotal.toFixed(2)}
                                            </span>
                                        </h2>
                                        <h2 className="text-sm font-medium text-white font-montserrat">
                                            Desconto aplicado: <span className="text-brand font-bold cart-price text-base">
                                                R$&nbsp;{(cart.subTotalDiscount).toFixed(2)}
                                            </span>
                                        </h2>
                                        <h2 className="text-lg font-semibold text-white font-montserrat">
                                            Valor final: <span className="text-brand font-bold cart-price text-xl">
                                                R$&nbsp;{(cart.subTotal - cart.subTotalDiscount).toFixed(2)}
                                            </span>
                                        </h2>
                                    </div>

                                    <button
                                        className="ms-auto px-5 py-3 flex gap-2 items-baseline justify-center rounded-full bg-green-500 hover:bg-green-500/80 transition text-white font-medium max-md:w-full"
                                        onClick={() => {
                                            navigate("/checkout");
                                        }}
                                        id="open-modal-cart">
                                        <i className="fa-solid fa-basket-shopping"></i> Finalizar compra
                                        <span className="md:hidden -ms-1">por</span>
                                        <span className="md:hidden -ms-1 cart-price">
                                            R$&nbsp;{cart.subTotalDiscount.toFixed(2)}
                                        </span>
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer store={store} />
        </>
    );
}

export default Cart;
