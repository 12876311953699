import React, {useEffect, useState} from 'react';
import Header from '../../../../components/Header';
import Banner from '../../../../components/Banner';
import Footer from '../../../../components/Footer';
import {findPosts} from "../../../../service/TemplateService";
import parser from 'html-react-parser';

function Home({store}) {

    const [copied, setCopied] = useState(false);
    const [posts, setPosts] = useState(null);

    useEffect(() => {
        findPosts(store.id).then(res => {
            if (res.error) {
                return;
            }

            setPosts(res.response.posts);
        })
    }, [store.id])

    if (!posts) {
        return;
    }
    return (
        <>
            <Header store={store}/>
            <section className="bg-background text-textPrimary flex items-center justify-center">
                <div className='container px-5 mt-[1rem] pb-[3rem]'>
                    <Banner store={store}/>

                    <div className="flex gap-6 py-5 flex-col lg:flex-row">

                        <div
                            className="rounded-xl p-6 bg-zinc-900 text-white w-full lg:w-[500px] shadow-lg transform transition-all hover:scale-105 hover:shadow-xl">
                            <h1 className="font-semibold text-xl">Meta do mês</h1>
                            <p className="text-sm text-gray-200 mt-2">Ajude-nos a continuar online.</p>
                            <div className="goal-bar mt-4 relative h-2 rounded-full bg-gray-800">
                                <div className="absolute top-0 left-0 h-full rounded-full bg-green-500"
                                     style={{width: '100%'}}></div>
                            </div>
                            <h3 className="text-sm text-green-400 mt-2 font-semibold">100% completo</h3>
                        </div>

                        <div
                            className="w-full flex items-center h-40 max-md:py-5 rounded-xl max-md:flex-col max-md:h-full bg-gradient-to-b md:bg-gradient-to-r from-zinc-900 to-green-600/80 from-30%">
                            <div
                                className="flex flex-col justify-center pl-6 h-full w-full rounded-xl max-md:w-full max-md:text-center max-md:px-5 max-md:from-transparent">
                                <h2 className="text-2xl text-white font-medium max-md:text-2xl">Doadores do mês</h2>
                                <p className="text-base font-medium text-muted-foreground mt-1 max-md:text-base">Veja
                                    quem mais contribuiu com o nosso servidor!</p>
                                <button
                                    id="copy-ip"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigator.clipboard.writeText(store.serverIp).then(() => {
                                            setCopied(true);
                                            setInterval(() => {
                                                setCopied(false);
                                            }, 2000);
                                        })
                                    }}
                                    className="mt-4 text-base text-white font-medium max-w-72 backdrop-blur-sm bg-green-600 hover:bg-green-800 transition py-1.5 rounded w-full max-md:mx-auto max-md:text-sm border border-green-900"
                                >
                                    <span id="copy-ip">{(copied ? "IP copiado!" : "Acesse o servidor")}</span>
                                </button>
                            </div>
                            <div
                                className="flex justify-end gap-6 items-center w-full max-w-3xl pr-6 max-md:mt-10 max-md:p-0 max-md:justify-center">

                                {['leozadaxz', 'Bremado', 'Herobrine'].map((user, index) => (
                                    <div key={index} className="relative flex flex-col items-center gap-2 mt-[12px]">
                                        <div
                                            className="h-[22px] px-2 rounded-sm bg-black/40 text-brand font-semibold flex justify-center items-center text-sm border border-brand whitespace-nowrap">
                                            {index + 1}º - {user}
                                        </div>
                                        <img className="rounded-sm" src={`https://mc-heads.net/avatar/${user}/40.png`}
                                             alt={user}/>
                                        <p className="font-medium text-sm">R$ 00,00</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    {posts.length !== 0 ? posts.map(post => (
                        <div className="mt-8">
                            <div
                                className="border text-muted-foreground p-6 rounded-xl flex flex-col gap-4 bg-transparent">

                                <div className="flex justify-between items-center">
                                    <h2 className="text-xl font-semibold text-white">{post.title}</h2>

                                    <div className="flex items-center gap-2">
                                        <img src={post.authorAvatar} alt="Autor"
                                             className="rounded-full w-8 h-8"/>
                                        <span className="text-sm text-gray-300">{post.author}</span>
                                    </div>
                                </div>

                                <p className="text-base text-gray-400">{parser(post.content)}</p>
                            </div>
                        </div>
                    )) : (
                        <div className="mt-8">
                            <div className="border text-muted-foreground p-6 rounded-xl flex items-center gap-2">
                                <i className="fa-regular fa-newspaper text-3xl"></i>
                                <p>
                                    Nenhuma postagem encontrada.
                                </p>
                            </div>
                        </div>
                    )}


                </div>
            </section>

            <Footer store={store}/>
        </>
    );
}

export default Home;
